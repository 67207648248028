import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import CartButton from "./shared/cartButton"
import Menu from "./nav/Menu"
import Burger from "./nav/Burger"

const Header = ({ siteTitle }) => {
  const [open, setOpen] = useState(false)
  return (
    <HeaderMenu>
      <Link className="title" to="/">
        velikeslike.com
      </Link>
      <Menu open={open} />
      <CartButton />
      <Burger open={open} setOpen={setOpen} />
    </HeaderMenu>
  )
}

export default Header

const HeaderMenu = styled.header`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  height: 70px;
  padding-bottom: 20px;
  max-width: 1440px;
  margin: 0 auto;
  width: calc(100% - 20px);

  a {
    color: #524448;
    letter-spacing: 0.1em;
    font-size: 24px;
    font-weight: 300;
    margin-left: 10px;
  }
  @media screen and (max-width: 1024px) {
    .title {
      font-size: 18px;
    }
  }
`
