import React, { useContext } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { CartContext } from "../../context/cartContext"
import CartIcon from "../../images/svg/cart.svg"

const CartButton = ({ count }) => {
  const [cart] = useContext(CartContext)

  return (
    <Cart to="/cart">
      <CartIcon />
      <span>{cart.length}</span>
    </Cart>
  )
}

export default CartButton

const Cart = styled(Link)`
  position: relative;
  svg {
    fill: #524448;
    height: 30px;
    width: 30px;
    transition: all 0.3s;
  }
  span {
    background-color: #ac6063;
    border-radius: 50%;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    padding: 2px 2px 2px 4px;
    position: absolute;
    right: -10px;
    top: -7px;
  }
`
