import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import Chevron from "../../images/svg/chevron.svg"

const Menu = ({ open }) => (
  <StyledMenu open={open}>
    <ul className="menu">
      <li>
        <p className="navItem">
          Slike na platnu <Chevron />
        </p>

        <ul>
          <li>
            <Link to="/jednodjelne-slike" className="navItemSm">
              Jednodjelne slike
            </Link>
          </li>
          <li>
            <Link to="/dvodjelne-slike" className="navItemSm">
              Dvodjelne slike
            </Link>
          </li>
          <li>
            <Link to="/trodjelne-slike" className="navItemSm">
              Trodjelne slike
            </Link>
          </li>
          <li>
            <Link to="/cetverodjelne-slike" className="navItemSm">
              Cetverodjelne slike
            </Link>
          </li>
          <li>
            <Link to="/petodjelne-slike" className="navItemSm">
              Petodjelne slike
            </Link>
          </li>
        </ul>
      </li>
      <li>
        <Link className="navItem" to="/tapete">
          Tapete
        </Link>
      </li>
      <li>
        <Link className="navItem" to="/aktuelno">
          Aktuelno
        </Link>
      </li>
    </ul>
  </StyledMenu>
)

const StyledMenu = styled.nav`
  .menu {
    display: flex;
    justify-content: center;
    align-items: center;
    .navItem {
      cursor: pointer;
      letter-spacing: 0.1em;
      font-size: 16px;
      font-weight: 400;
      margin: 0 60px;
    }
    .navItemSm {
      font-size: 14px;
    }

    li {
      position: relative;
    }

    li ul {
      background-color: #fff;
      visibility: hidden;
      opacity: 0;
      position: absolute;
      transition: all 0.5s ease;
      margin-top: 1rem;
      left: 0;
      padding: 10px;
      z-index: 2;
      width: 300px;
      li {
        padding: 20px 0;
      }
    }

    li:hover > ul,
    li ul:hover {
      visibility: visible;
      opacity: 1;
    }

    li ul li {
      clear: both;
      width: 100%;
    }

    svg {
      transform: translateY(-1px);
    }
  }

  @media screen and (max-width: 1024px) {
    .menu {
      background-color: #fff;
      flex-direction: column-reverse;
      position: absolute;
      top: 70px;
      right: 20px;
      z-index: 2;
      align-items: flex-start;
      display: ${({ open }) => (open ? "flex" : "none")};
      transition: all 0.3s;

      li {
        margin: 20px 0;
      }

      li ul {
        background: none;
        margin-top: 0;
        opacity: 1;
        padding: 5px;
        position: relative;
        visibility: visible;
        text-align: center;
        li {
          padding: 10px 0;
        }
      }
    }
  }
`

export default Menu
