import React from "react"
import { createGlobalStyle } from "styled-components"

import Header from "./header"
import MessengerCustomerChat from "react-messenger-customer-chat"

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <Header />
      <main>{children}</main>
      <MessengerCustomerChat
        pageId="109414937236729"
        appId=""
        htmlRef="fb-customerchat"
      />
    </>
  )
}

const GlobalStyle = createGlobalStyle`
  * {
    
    box-sizing: border-box;
  }

  body {
    background-color: #F7F4F2;
    color: #524448;
    font-family: 'Mulish', 'sans-serif'
  }
  a {
    color: #4881D7;
    text-decoration: none;
    :hover {
      opacity: 0.95;
    }
  }
  ul {
    padding: 0;
    list-style-type: none;
  }
`

export default Layout
